<template>
  <div class="Profile">
    <div class="page">
      <div class="header">
        <span class="title">個人資料</span>
      </div>
      <div class="container" v-loading="loading">
        <el-scrollbar>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
            label-position="top"
            size="small"
          >
            <el-form-item label="舊密碼" prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密碼" prop="new_password">
              <el-input
                type="password"
                v-model="ruleForm.new_password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="確認密碼" prop="confirm_password">
              <el-input
                type="password"
                v-model="ruleForm.confirm_password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from "blueimp-md5";

export default {
  name: "Profile",

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请確認新密碼"));
      } else if (value !== this.ruleForm.new_password) {
        callback(new Error("兩次鍵入密碼不一致"));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      ruleForm: {
        password: "123456",
        new_password: "123456",
        confirm_password: "123456",
      },
      rules: {
        password: [
          { required: true, message: "請輸入舊密碼", trigger: "blur" },
          { min: 6, message: "請輸入不少於6位字符", trigger: "blur" },
        ],
        new_password: [
          { required: true, message: "请输入新密碼", trigger: "blur" },
          { min: 6, message: "請輸入不少於6位字符", trigger: "blur" },
        ],
        confirm_password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePass,
          },
          { min: 6, message: "請輸入不少於6位字符", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    submit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (!valid) {
          this.$message.error("填寫資料錯誤，請檢查");
          return;
        }

        this.loading = true;

        const result = await this.$fetch("password", {
          method: "PUT",
          body: {
            password: md5(this.ruleForm.password),
            new_password: md5(this.ruleForm.new_password),
          },
        });

        this.loading = false;

        if (result.error) {
          this.$message.error(result.message);
          return;
        }

        this.ruleForm = {
          password: "",
          new_password: "",
          confirm_password: "",
        };

        this.$message.success("修改密碼成功");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>